import { Dialog, DialogContent, Link, DialogTitle, DialogActions, IconButton, Button, Divider, Box, Alert, CircularProgress } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import EnchantedForm from '../EnchantedForm/EnchantedForm';
import { useState, useEffect } from 'react';
import LOIform from '../../templates/LOIform';
import useAuth from '../../hooks/useAuth';
import { format as date_format, parseISO } from 'date-fns';
import * as Yup from 'yup';
import SignatureDialog from '../signature/SignatureDialog';
import toast from 'react-hot-toast';
import { createItem } from "../../services/api/ModelAPI";
import LetterOfIntentPDFtemplate from './LetterOfIntentPDFtemplate';
import { PDFViewer,pdf } from '@react-pdf/renderer';
import { uploadFile } from '../../services/api/ListingAPI';

const LetterOfIntentDialog = (props) => {
    const { open, setOpen, match } = props;
    const [preview, setPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isSignatureDialogOpen, setSignatureDialogOpen] = useState(false);
    const [data, setData] = useState(null);
    const [pdfContext, setPdfContext] = useState(null);
    const [signature, setSignature] = useState(null);
    const { currentOrganization, user } = useAuth();

    useEffect(() => {
        //console.log("inputs",inputs);
        setData(null);
        setPreview(null);
        setPdfContext(null);
        setSignature(null);
    }, [open]);

    useEffect(() => {
        if (signature) handlePreview(data);
    }, [signature]);

    const getListingPrice = () => {
        if (match && match.get && match.get("listing") && !match.get("listing").get("price_hidden"))
            return match.get("listing").get("price") || '';
        return '';
    }

    const getClosingDate = () => {
        if (data?.closing_date?.iso)
            return new Date(data?.closing_date.iso);

        let date = new Date();
        date.setDate(date.getDate() + 45);
        return date;
    }

    const inputs = [{
        label: 'Purchaser Legal Name',
        type: 'string',
        name: 'purchaser_name',
        value: data?.purchaser_name || currentOrganization ? currentOrganization.get("name") : '',
        grid_props: { xs: 12, pr: 1 },
        validation: Yup.string()
            .required('Legal Name is required.')
            .max(255)
            .nullable(),
    },
    {
        label: 'Signee Legal Name',
        type: 'string',
        name: 'signee_name',
        value: data?.signee_name || ((user.get("first_name") || '') + ' ' + (user.get("last_name") || '')),
        grid_props: { xs: 12, sm: 6, pr: 1 },
        validation: Yup.string()
            .required('Signee Name is required.')
            .max(255)
            .nullable(),
    }, {
        label: 'Signee title',
        type: 'string',
        name: 'signee_title',
        value: data?.signee_title || "",
        grid_props: { xs: 12, sm: 6, pr: 1 },
        validation: Yup.string()
            .required('Signee Title is required.')
            .max(255)
            .nullable(),
    }, {
        label: 'Purchase price',
        type: 'Number',
        name: 'purchase_price',
        thousandSeparator: true,
        decimalScale: 0,
        startAdornmentText: "$",
        value: data?.purchase_price || getListingPrice(),
        grid_props: { xs: 12, sm: 6, pr: 1 },
        validation: Yup.number()
            .transform((_value, originalValue) => Number(originalValue?.toString().replace(/,/g, '')))
            .typeError('A number is required')
            .positive('Please enter a positive number.')
            .required('Purchase price is required.')
            .nullable(),
    }, {
        label: 'Earnest Money Deposit',
        type: 'Number',
        name: 'earnest_money_deposit',
        thousandSeparator: true,
        decimalScale: 0,
        startAdornmentText: "$",
        value: data?.earnest_money_deposit || '',
        grid_props: { xs: 12, sm: 6, pr: 1 },
        validation: Yup.number()
            .transform((_value, originalValue) => Number(originalValue?.toString().replace(/,/g, '')))
            .typeError('A number is required')
            .positive('Please enter a positive number.')
            .required('Earnest Money Deposit is required.')
            .nullable(),
    }, {
        label: 'Due Diligence Period (Days)',
        type: 'Number',
        name: 'dd_period',
        value: data?.dd_period || 45,
        grid_props: { xs: 12, sm: 6, pr: 1 },
        validation: Yup.number()
            .typeError('A number is required')
            .positive('Please enter a positive number.')
            .required('Due Diligence Period is required.')
            .nullable(),
    },
    {
        label: 'Closing Date',
        type: 'date',
        name: 'closing_date',
        value: getClosingDate(),
        grid_props: { xs: 12, sm: 6, pr: 1 },
        validation: Yup.date()
            .required('Date is required.')
            .nullable(),
    },
    //Title Company ???
   /* {
        label: 'Conveyance', //TODO what is it?
        type: 'string',
        name: 'conveyance',
        value: '',
        grid_props: { xs: 12, sm: 6, pr: 1 },
        //validation - TODO
    }, {
        label: 'Brokerage', //TODO what is it?
        type: 'string',
        name: 'brokerage',
        value: '',
        grid_props: { xs: 12, sm: 6, pr: 1 },
        //validation - TODO
    },*/ {
        label: 'Other Terms (Optional)',
        type: 'text',
        name: 'other_terms',
        value: data?.other_terms || '',
        rows: 3,
        grid_props: { xs: 12, pr: 1 },
    }, {
        label: 'Requested Due Diligence Items (Optional):',
        type: 'text',
        name: 'requested_dd_items',
        value: data?.requested_dd_items || '',
        rows: 3,
        grid_props: { xs: 12, pr: 1 },
    }
    ]

    const pdfPreview=(
        <LetterOfIntentPDFtemplate data={pdfContext} />
    )

    const renderAddress = () => {
        return match?.get("listing")?.get("address_line1") + ", " + match?.get("listing")?.get("city") + ", " + match?.get("listing")?.get("state") + " " + match?.get("listing")?.get("post_code") + ", " + match?.get("listing")?.get("country");
    }

    const format_currency = (val) => {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
        });
        if (typeof val == 'string') val = parseInt(val);
        return formatter.format(val);
    }

    const handlePreview = async (values) => {
        setData(values);
        let context = { ...values };
        context.seller_name = 'Seller #' + match?.get("listing_organization")?.id;
        context.listing_name = match?.get("listing").get("title");
        context.listing_address = renderAddress();
        context.purchase_price = format_currency(context.purchase_price);
        context.earnest_money_deposit = format_currency(context.earnest_money_deposit);
        if (!context.closing_date instanceof Date) {
            context.closing_date = new Date(context.closing_date?.iso || context.closing_date)
        }
        console.log(context.closing_date);
        context.closing_date = date_format(context.closing_date, 'MM/dd/yy');
        context.today_date = date_format(new Date(), 'MM/dd/yy');
        if (process.env.REACT_APP_USE_REACT_PDF == 'true') {
            //pdf renderer doesn't allow html tags, so these values shouldn't contain it
            context.purchaser_logo = currentOrganization.get("logo")?.url();
            context.signature = signature;
            setPdfContext(context);
        } else {
            //we can use html tags if we don't use react-pdf
            context.purchaser_logo = currentOrganization.get("logo") ? '<div style="width:100%;text-align:right;"><img src="' + currentOrganization.get("logo").url() + '" style="width:140px;" /></div>' : '';
            context.signature = signature ? '<img src="' + signature + '" width="100" style="margin-left:10px"/>' : '_____________';
            if (context.other_terms) context.other_terms = "<br />Other terms:<br/>" + context.other_terms.replaceAll("\n", "<br />") + "<br>";
            if (context.requested_dd_items) context.requested_dd_items = "<br />Requested Due Diligence Items:<br/>" + context.requested_dd_items.replaceAll("\n", "<br />") + "<br>";
            let message = LOIform;
            Object.keys(context).forEach(key => {
                message = message.replaceAll('{' + key + '}', context[key]);
            });
            setPreview(message);
        }

    };

    const handleEdit = () => {
        setPreview(null);
        setPdfContext(null);
    }

    const handleCancel = () => {
        setOpen(false);
    };

    const handleSend = async () => {
        if (!signature)
            setSignatureDialogOpen(true);
        else {
            setLoading(true);
            let values = { ...data };//Parse has a bug with encoding date in beforeSave, so we have to replace date
            if (!values.closing_date instanceof Date) {
                values.closing_date = new Date(values.closing_date?.iso || values.closing_date)
            }
            let rData={ body: preview, signature: signature, data: values,type:"LOI",organization:currentOrganization };
            if (pdfContext) { //upload PDF by client
                const blob = await pdf(pdfPreview).toBlob();
                const file = await uploadFile(blob, "LOI.pdf");
                rData.pdf=file;
            } else {
                rData.body=preview;//PDF will be generated in server
            }
            //console.log(values.purchase_price);
            createItem("signed_document", rData).then(sd => {
                return createItem("deal", {
                    match: match,
                    loi: sd,
                    price: parseInt(values.purchase_price),
                    dd_period: parseInt(values.dd_period),
                    em_deposit: parseInt(values.earnest_money_deposit),
                    expected_closing_date: values.closing_date
                })
            }).then(deal => {
                toast.success("Letter of intent succesfully sent");
                setSignature(null);
                setOpen(false);
                setLoading(false);
            }).catch(err => {
                console.log(err);
                setLoading(false);
                //toast.error(err?.message || err || 'There is an error occurred. Please try again later or contact administrator')
            });
        }
    }

    const cancelBtn =
        <Button autoFocus onClick={handleCancel} >
            Cancel
        </Button>




    return (
        <>
            <Dialog
                open={open}
                onClose={() => { setOpen(false); }}
                PaperProps={{
                    style: {
                        minWidth: '50%',
                        minHeight: '80%',
                        maxWidth: '1000px',
                        padding: '20px'
                    },
                }}
                id="loi_dialog"
            >
                <DialogTitle sx={{ p: 0 }}>
                    Letter of Intent
                    <IconButton
                        aria-label="close"
                        onClick={() => { setOpen(false); }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    ><CloseIcon /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ px: preview ? 0 : 2 }}>
                    {pdfContext &&
                        <PDFViewer style={{ border: 0, height: '70vh', width: '100%' }}>
                            {pdfPreview}
                        </PDFViewer>
                    }
                    {preview &&
                        <Box sx={{ border: 1, p: 4, borderColor: '#ccc' }} dangerouslySetInnerHTML={{ __html: preview }} />
                    }
                    {!preview && !pdfContext &&
                        <>
                            Please fill out this form in order to prepare the Letter of Intent:
                            {!currentOrganization.get("logo") &&
                                <Alert severity="info">
                                    <b>Tip</b>: <Link
                                        color="textSecondary"
                                        component={RouterLink}
                                        to="/account/info"
                                        variant="body2"
                                    >
                                        Upload your logo
                                    </Link>  to personalize your Letter of Intent
                                </Alert>
                            }
                            <EnchantedForm fields={inputs} onSubmit={handlePreview} addl_actions={cancelBtn} okBtnText="Preview" />
                        </>
                    }
                </DialogContent>
                {(preview || pdfContext) &&
                    <DialogActions>
                        {cancelBtn}
                        <Button onClick={handleEdit} >
                            Edit
                        </Button>
                        <Button onClick={handleSend}
                            disabled={loading}
                            color="primary"
                            variant="contained">
                            {signature ? 'Send' : 'Sign'}
                            {loading &&
                                <CircularProgress size={12} sx={{ ml: 1 }} />
                            }
                        </Button>
                    </DialogActions>
                }
            </Dialog>
            <SignatureDialog open={isSignatureDialogOpen} setOpen={setSignatureDialogOpen} setSignature={setSignature} />
        </>
    )
}

export default LetterOfIntentDialog;