import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import routes from './routes';
import useScrollReset from './hooks/useScrollReset';
import { Toaster } from 'react-hot-toast';
import LoadingScreen from './components/LoadingScreen';
import { PARSE_APP_ID,API_URL } from './config';
import useSettings from './hooks/useSettings';
import { createCustomTheme } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import ReactGA from 'react-ga';
import { updateLiveQueryClasses } from './services/api/API';
const Parse = require('parse');

/** Please keep in mind react runs every class twice in developement mode and it's completely OK! */
const App = () => {
  //console.log("API "+PARSE_APP_ID+": "+API_URL);
  console.log("HNF",process.env.REACT_APP_HIDE_NEWFEATURES);
  const content = useRoutes(routes);
  const { settings } = useSettings();
  useScrollReset();
  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });
  if (process.env.REACT_APP_GOOGLE_TRACKING_ID) ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);
  Parse.initialize(PARSE_APP_ID);
  Parse.serverURL = API_URL;
  updateLiveQueryClasses();
  const auth = useAuth();

  if (process.env.REACT_APP_DISABLE_FRONTLOG!=='true') { require('./console');  }

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_TRACKING_ID) ReactGA.pageview(window.location.pathname + window.location.search);
  }, [content]); 

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position="top-center" />
      {auth?.isInitialized ? content : <LoadingScreen />}
    </ThemeProvider>
  );
}
//App.whyDidYouRender = true

export default App;
